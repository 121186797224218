<template>
  <TransitionRoot as="template" :show="props.isOpen">
    <Dialog class="relative z-10" @close="emits('close-dialog')">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-neutral_950 bg-opacity-30 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div
          class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative p-6 bg-primary_950 rounded-[12px] transform overflow-hidden shadow-xl transition-all sm:my-8 w-full max-w-[622px]"
            >
              <form @submit.prevent="repostPrediction">
                <div class="flex flex-col gap-2 w-full">
                  <div class="flex items-center justify-between">
                    <div class="h-[40px] flex items-center">
                      <p
                        class="text-[12px] font-bold leading-[150%] tracking-[2.4px] uppercase text-base_white"
                      >
                        {{ $t('createPost') }}
                      </p>
                    </div>
                    <ClientOnly>
                      <ion-icon
                        class="text-base_white cursor-pointer"
                        name="close-outline"
                        @click="emits('close-dialog')"
                      />
                    </ClientOnly>
                  </div>
                  <div class="text-left">
                    <InputText
                      v-model="additionalText"
                      size="medium"
                      :placeholder-text="$t('writeComment')"
                      :error="v$.additionalText.$error"
                      :error-messages="v$.additionalText.$errors"
                    />
                  </div>
                  <div
                    :class="
                      props.prediction.game_status == 'won' ? 'bg-primary_850' : 'bg-neutral_500'
                    "
                    class="flex items-center gap-3 p-4 rounded-[12px] bg-neutral_500"
                  >
                    <img
                      src="../../assets/img/challengeTextLogo.png"
                      class="h-8 hidden sm:block"
                      alt="challenge logo text variant"
                    />
                    <img
                      src="../../assets/img/logoProfesorsChallange.png"
                      class="h-16 block sm:hidden"
                      alt="challenge logo"
                    />
                    <div class="h-[64px] sm:h-[32px] bg-primary_800 w-px"/>
                    <p
                      class="py-[2px] text-[14px] leading-[150%] tracking-[-0.28px]"
                      :class="
                        props.prediction.game_status == 'won'
                          ? 'text-primary_500'
                          : 'text-neutral_50'
                      "
                    >
                      {{ props.prediction.game }}, {{ $t('odd').toLowerCase() }}
                      {{ props.prediction.odd }}
                    </p>
                    <div class="grow flex items-center justify-end">
                      <p
                        v-if="props.prediction.game_status == 'void'"
                        class="text-[12px] sm:w-[72px] font-medium text-neutral_50 text-center"
                      >
                        {{ $t('delayed') }}
                      </p>
                      <ClientOnly v-else-if="props.prediction.game_status != 'in_progress'">
                        <ion-icon
                          class="sm:w-[72px]"
                          :class="
                            props.prediction.game_status == 'won'
                              ? 'text-primary_500'
                              : 'text-error_400'
                          "
                          :name="
                            props.prediction.game_status == 'won' ? 'checkmark-sharp' : 'remove'
                          "
                        />
                      </ClientOnly>
                    </div>
                  </div>
                  <div class="flex items-center gap-2">
                    <input
                      v-model="hideDialog"
                      type="checkbox"
                      class="accent-primary_500 w-5 h-5"
                    />
                    <p
                      class="text-[14px] text-neutral_50 font-medium leading-[150%] tracking-[-0.28px]"
                    >
                      {{ $t('hideDialog') }}
                    </p>
                  </div>
                  <div class="flex items-center justify-between mt-3">
                    <ButtonComponent
                      type="text"
                      :text="$t('notNow')"
                      @click="emits('close-dialog')"
                    />
                    <ButtonComponent
                      :is-loading="isLoading"
                      :form-element="true"
                      :text="$t('createPost')"
                    />
                  </div>
                </div>
              </form>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import useVuelidate from '@vuelidate/core';
import { required, helpers, minLength } from '@vuelidate/validators';
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue';
import type { UserPrediction } from '~/interfaces';
import { postRepository } from '~/repositories/postRepository';
import { useDialogStore } from '~/store/dialogStore';

const { t } = useI18n();
const { $api } = useNuxtApp();
const {
  value: storageHideDialog,
  saveValue: saveHideDialog,
  loadValue: loadHideDialog
} = useTmpLocalStorage<boolean>('hideRepostPredictionDialog');

onMounted(() => {
  loadHideDialog();
  if (storageHideDialog.value) hideDialog.value = storageHideDialog.value;
});

const postRepo = postRepository($api);
// const localePath = useLocalePath();
// const router = useRouter();
const dialogStore = useDialogStore();

const emits = defineEmits(['close-dialog']);

const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true
  },
  prediction: {
    type: Object as PropType<UserPrediction>,
    required: true
  }
});
const additionalText = ref('');
const isLoading = ref(false);
const hideDialog = ref(false);
const rules = {
  additionalText: {
    required: helpers.withMessage(`${t('commentRequired')}`, required),
    minLength: helpers.withMessage(t('commentTooShort'), minLength(20))
  }
};
const v$ = useVuelidate(rules, { additionalText }, { $scope: false });

const repostPrediction = async () => {
  const isFormValid = await v$.value.$validate();
  if (isFormValid) {
    try {
      isLoading.value = true;
      await postRepo.repostPost(additionalText.value, props.prediction.id, 'prediction');
      isLoading.value = false;
      // router.push(localePath('/'));
      emits('close-dialog');
      dialogStore.createToasterNotification(
        `success`,
        `${t('predictionSuccessTitle')}`,
        `${t('predictionSuccessMessageToaster')}`
      );
    } catch (err) {
      console.log(err);
    }
  }
};
const saveNewHideDialogValue = () => {
  saveHideDialog(hideDialog.value);
};
watch(
  () => hideDialog.value,
  () => {
    saveNewHideDialogValue();
  }
);
</script>
